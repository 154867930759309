import { createRoot } from "react-dom/client";

const renderOnce = (containerId, component) => {
  if(!window.rendered[containerId]){
    let container = document.getElementById(containerId);
    let root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(component);
    window.rendered[containerId] = true;
  }
}

const renderComponents = (containerId, component) => {
  if(!window.rendered){
    window.rendered = {};
  }
  renderOnce(containerId, component);

  document.addEventListener("turbo:render", function () {
    renderOnce(containerId, component);
  });
};

export default renderComponents;
